import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MenuItem, Select, Slider, SliderThumb } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  format,
  isSameDay,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';

import { SelectionTime } from '@/constants/DateTimes';
import { AdvertCurrencyCalculationsModel } from '@/models/Adverts';
import { MetaAdsFormModel } from '@/models/Meta';
import { getNextQuarterHour } from '@/utility/date';

const BudgetAndDuration = ({
  currencyCalculations,
  canStep,
}: {
  currencyCalculations: AdvertCurrencyCalculationsModel;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string>();

  const { setValue, watch } = useFormContext<MetaAdsFormModel>();
  const formBudget = watch('ad.budget');
  const formStartTime = watch('ad.startTime');
  const formEndTime = watch('ad.endTime');
  const formPriceLocale = watch('priceLocale');

  const calculatedBudget = useMemo(() => formBudget || 500, [formBudget]);

  const minDate = useMemo(() => setSeconds(setMinutes(setHours(new Date(), 0), 0), 0), []);
  const maxDate = useMemo(
    () =>
      setSeconds(
        setMinutes(setHours(addDays(new Date(), calculatedBudget / currencyCalculations.dailySpend - 1), 0), 0),
        0
      ),
    [calculatedBudget, currencyCalculations.dailySpend]
  );
  const defaultDateRange: [Date, Date] = useMemo(() => [minDate, maxDate], [minDate, maxDate]);

  const parseTime = (timeString: string, referenceDate: Date) => {
    const [hour, minute] = timeString.split(':').map(Number);
    return setMinutes(setHours(referenceDate, hour), minute);
  };

  const isStartTimeDisabled = useCallback(
    (item: string): boolean => {
      if (new Date(formStartTime).getDate() > new Date().getDate()) {
        return false;
      }
      const itemDate = setMilliseconds(setSeconds(parseTime(item, new Date()), 0), 0);
      const nextAvailableStartTime = setMilliseconds(setSeconds(addHours(getNextQuarterHour(new Date()), 1), 0), 0);
      return itemDate < nextAvailableStartTime;
    },
    [formStartTime]
  );

  const isEndTimeDisabled = useCallback(
    (item: string): boolean => {
      const itemDate = setMilliseconds(setSeconds(parseTime(item, new Date(formEndTime)), 0), 0);
      const startTimeDate = setMilliseconds(setSeconds(new Date(formStartTime), 0), 0);
      if (isSameDay(itemDate, startTimeDate)) {
        return itemDate < addMinutes(startTimeDate, 15);
      }
      return false;
    },
    [formEndTime, formStartTime]
  );

  const handleUpdateStartTime = useCallback(
    (value: string) => {
      const time = value.split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      setValue('ad.startTime', setMinutes(setHours(new Date(formStartTime), hours), minutes).toString());
    },
    [formStartTime, setValue]
  );

  const handleUpdateEndTime = useCallback(
    (value: string) => {
      const time = value.split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      setValue('ad.endTime', setMinutes(setHours(new Date(formEndTime), hours), minutes).toString());
    },
    [formEndTime, setValue]
  );

  useEffect(() => {
    canStep(!!(formBudget && formStartTime && formEndTime && !error));
  }, [formBudget, canStep, formEndTime, error, formStartTime]);

  useEffect(() => {
    if (
      formBudget &&
      formBudget / currencyCalculations?.dailySpend <= differenceInDays(new Date(formEndTime), new Date(formStartTime))
    ) {
      setError("Budget won't cover this");
    } else {
      setError(undefined);
    }
  }, [formBudget, currencyCalculations?.dailySpend, formStartTime, formEndTime]);

  const handleBudgetChange = (budget: number) => {
    setValue('ad.budget', budget);
  };

  const handleDateChange = (value: DateRange<Date>) => {
    if (!value[0]) return;

    const updateDateTime = (date: Date, time: string) => {
      const hours = new Date(time).getHours();
      const minutes = new Date(time).getMinutes();
      date.setHours(hours, minutes, 0, 0);
      return date.toISOString();
    };

    setValue('ad.startTime', updateDateTime(value[0], formStartTime));

    const endTime = value[1] || value[0];
    setValue('ad.endTime', updateDateTime(endTime, formEndTime));
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <h3>{t('SOCIAL-ADS.BUDGET-AND-DURATION')}</h3>
        <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.BUDGET-AND-DURATION-DESCRIPTION')}</p>
        <div className="card">
          <div className="d-flex gap20 flex-wrap">
            <div className="flex-w50p w100p-lg-down text-left">
              <h3>{t('SOCIAL-ADS.SET-YOUR-BUDGET')}</h3>
              <p className="text-faded mb48 mt8">{t('SOCIAL-ADS.SET-YOUR-BUDGET-DESCRIPTION')}</p>
            </div>
            <div className="flex-w50p w100p-lg-down">
              <div className="card-inner">
                <Slider
                  slots={{
                    thumb: (props: { children: ReactNode }) => {
                      const { children, ...other } = props;
                      return (
                        <SliderThumb {...other}>
                          {children}
                          <img className="slider-thumb-icon" src="/images/icons/SliderThumb.svg" alt="" />
                        </SliderThumb>
                      );
                    },
                  }}
                  defaultValue={currencyCalculations.startPrice}
                  step={currencyCalculations.dailySpend}
                  max={currencyCalculations.maxSpend}
                  min={currencyCalculations.minSpend}
                  value={calculatedBudget}
                  name="budget"
                  onChange={(_, value) => {
                    const budget = Array.isArray(value) ? value[0] : value;
                    handleBudgetChange(budget);
                  }}
                />
                <div className="d-flex jc-space-between mt-16">
                  <p className="small">
                    {`${getSymbolFromCurrency(formPriceLocale || 'GBP')}${currencyCalculations.minSpend}`}
                  </p>
                  <p className="small">
                    {`${getSymbolFromCurrency(formPriceLocale || 'GBP')}${currencyCalculations.maxSpend}`}
                  </p>
                </div>
                <div className="d-flex gap20 mt20">
                  <div className="card-inner-inner flex-w50p text-center p16">
                    <h3>{`${getSymbolFromCurrency(formPriceLocale || 'GBP')}${calculatedBudget.toLocaleString()}`}</h3>
                    <p className="text-faded">Budget</p>
                  </div>
                  <div className="card-inner-inner flex-w50p text-center p16">
                    <h3>{calculatedBudget / currencyCalculations.dailySpend}</h3>
                    <p className="text-faded">Max days</p>
                  </div>
                </div>
                <p className="text-faded small mt16 text-left">{t('SOCIAL-ADS.BUDGET-RECOMMENDATION')}</p>
                <p className="text-faded small mt8 text-left">{t('SOCIAL-ADS.BUDGET-NOTE')}</p>
              </div>
            </div>
          </div>
          <div className="d-flex form-divider mt10 mb20">
            <div className="line"></div>
          </div>
          <div className="d-flex gap20 flex-wrap">
            <div className="flex-w50p w100p-lg-down text-left">
              <h3>{t('SOCIAL-ADS.SET-YOUR-DATES')}</h3>
              <p className="text-faded mb48 mt8">{t('SOCIAL-ADS.SET-YOUR-DATES-DESCRIPTION')}</p>
            </div>
            <div className="flex-w50p w100p-lg-down">
              <div className="card-inner">
                <div className="d-flex jc-center">
                  <p className="fw-bold">{format(new Date(formStartTime), 'dd/MM/yyyy')}</p>
                  <p className="ml8 mr8"> - </p>
                  <p className="fw-bold">{format(new Date(formEndTime), 'dd/MM/yyyy')}</p>
                </div>
                {error && <p className="text-error small mt10">{error}</p>}
                <DateRangeCalendar
                  calendars={1}
                  minDate={minDate}
                  defaultValue={defaultDateRange}
                  value={[new Date(formStartTime), new Date(formEndTime)]}
                  onChange={(value) => handleDateChange(value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex form-divider mt10 mb20">
            <div className="line"></div>
          </div>
          <div className="d-flex gap20 flex-wrap">
            <div className="flex-w50p w100p-lg-down text-left">
              <h3>{t('SOCIAL-ADS.SET-YOUR-START-TIME')}</h3>
              <p className="text-faded mb48 mt8">
                {t('SOCIAL-ADS.SET-YOUR-TIME-DESCRIPTION')}
                <span className="text-white fw-bold">{format(new Date(formStartTime), 'do MMMM yyyy')}</span>
                {t('SOCIAL-ADS.SET-YOUR-START-TIME-DESCRIPTION')}
              </p>
            </div>
            <div className="flex-w50p w100p-lg-down">
              <div className="card-inner">
                <Select
                  className="time-selector"
                  value={format(new Date(formStartTime), 'HH:mm')}
                  defaultValue={format(new Date(formStartTime), 'HH:mm')}
                  onChange={(event) => handleUpdateStartTime(event.target.value)}
                >
                  {SelectionTime().map((item, index: number) => (
                    <MenuItem key={index} value={item} disabled={isStartTimeDisabled(item)}>
                      <div className="w100p">{item}</div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="d-flex form-divider mt10 mb20">
            <div className="line" />
          </div>
          <div className="d-flex gap20 flex-wrap">
            <div className="flex-w50p w100p-lg-down text-left">
              <h3>{t('SOCIAL-ADS.SET-YOUR-END-TIME')}</h3>
              <p className="text-faded mb48 mt8">
                {t('SOCIAL-ADS.SET-YOUR-TIME-DESCRIPTION')}
                <span className="text-white fw-bold">{format(new Date(formEndTime), 'do MMMM yyyy')}</span>
                {t('SOCIAL-ADS.SET-YOUR-END-TIME-DESCRIPTION')}
              </p>
            </div>
            <div className="flex-w50p w100p-lg-down">
              <div className="card-inner">
                <Select
                  className="time-selector"
                  value={format(new Date(formEndTime), 'HH:mm')}
                  defaultValue={format(new Date(formEndTime), 'HH:mm')}
                  onChange={(event) => handleUpdateEndTime(event.target.value)}
                >
                  {SelectionTime().map((item, index: number) => (
                    <MenuItem key={index} value={item} disabled={isEndTimeDisabled(item)}>
                      <div className="w100p">{item}</div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
};

export default BudgetAndDuration;
