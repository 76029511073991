import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const DesignYourAds = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('SOCIAL-ADS.DESIGN-YOUR-ADS')}</h3>
      <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.DESIGN-YOUR-ADS-DESCRIPTION')}</p>
      <div className="card">{children}</div>
    </>
  );
};

export default DesignYourAds;
