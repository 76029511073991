import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const AudienceTargeting = ({
  renderGenderComponent,
  renderAgeRangeComponent,
  renderLocationTargetingComponent,
  renderInterestsComponent,
}: {
  renderGenderComponent: () => ReactNode;
  renderAgeRangeComponent: () => ReactNode;
  renderLocationTargetingComponent: () => ReactNode;
  renderInterestsComponent: () => ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('SOCIAL-ADS.AUDIENCE-TARGETING')}</h3>
      <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.AUDIENCE-TARGETING-DESCRIPTION')}</p>
      <div className="card">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w50p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.DEMOGRAPHIC-TARGETING')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.DEMOGRAPHIC-TARGETING-DESCRIPTION')}</p>
          </div>
          <div className="flex-w50p w100p-lg-down">
            <div className="card-inner p16">
              <p className="fw-bold text-left">{t('INSIGHTS-PAGE.COMMON.GENDER')}</p>
              {renderGenderComponent()}
            </div>
            <div className="card-inner mt8 p16">
              <p className="fw-bold text-left">{t('COMMON.AGE-RANGE')}</p>
              {renderAgeRangeComponent()}
            </div>
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w50p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.LOCATION-TARGETING')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.LOCATION-TARGETING-DESCRIPTION')}</p>
          </div>
          <div className="flex-w50p w100p-lg-down">{renderLocationTargetingComponent()}</div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w50p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.INTEREST-TARGETING')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.INTEREST-TARGETING-DESCRIPTION')}</p>
          </div>
          <div className="flex-w50p w100p-lg-down">{renderInterestsComponent()}</div>
        </div>
      </div>
    </>
  );
};

export default AudienceTargeting;
