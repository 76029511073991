import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import { formatDiscountPercentage } from '@/formatters/PriceFormatters';
import useSubscription from '@/hooks/account/useSubscription';
import usePricing from '@/hooks/pricing/usePricing';

import numberFormatter from '../../formatters/ShortNumberConverter';
import Subscription from '../subscription/Subscription';
import ButtonComponent from '../utility/microcomponents/Button';

const PaymentOptions = ({ type, amount, discount }: { type: string; amount: number; discount: number }) => {
  const { t } = useTranslation();
  const { playlistPitchFull } = usePricing();
  const { isSubscribed } = useSubscription();

  const [paymentPlan, setPaymentPlan] = useState<string>('one-off');

  const isSelected = (platform: string) => {
    return platform === paymentPlan;
  };

  useEffect(() => {
    if (isSubscribed) {
      setPaymentPlan('subscription');
    }
  }, [isSubscribed]);

  const playlistDetailsString = useMemo(() => {
    return t('SUBSCRIPTION.SUBSCRIPTION-PLAYLISTS-DETAILS').replace(
      '{{playlistDiscountPercentage}}',
      formatDiscountPercentage(playlistPitchFull) || ''
    );
  }, [t, playlistPitchFull]);

  return (
    <>
      <h3 className="text-left mb10">{t('SUBSCRIPTION.PAYMENT-OPTIONS')}</h3>
      <Subscription
        type="element"
        element={
          <div
            className={`subscription-banner cursor-pointer d-flex w100p ${
              isSelected('subscription') ? 'photo-selected' : ''
            }`}
          >
            <div>
              <div className="d-flex gap8 text-left">
                {type === 'playlist' && <h4>{t('SUBSCRIPTION.SUBSCRIBE-AND-SAVE')}</h4>}
                {type !== 'playlist' && <h4>{t('SUBSCRIPTION.SKIP-THE-FEE')}</h4>}
                <img className={`h20`} src="/images/logos/subscription-pro-badge.svg" alt="" />
              </div>
              {type !== 'playlist' && (
                <p className="text-faded small text-left">{t('SUBSCRIPTION.SUBSCRIPTION-ADS-DETAILS')}</p>
              )}
              {type === 'playlist' && <p className="text-faded small text-left">{playlistDetailsString}</p>}
              <div className="d-flex gap8">
                {type === 'playlist' && <h3>£{numberFormatter(amount - discount, 2)}</h3>}
                <div className="percentage-difference-container green mt4">
                  <p className="status-text active">{'Save £' + numberFormatter(discount, 2) + ' with pro'}</p>
                </div>
              </div>
            </div>
            <div className="mt-auto mb-auto ml-auto">
              {!isSelected('subscription') ? (
                <Icon className="material-symbols-outlined text-faded">circle</Icon>
              ) : (
                <Icon className="">check_circle</Icon>
              )}
            </div>
          </div>
        }
      />
      {!isSubscribed && (
        <ButtonComponent
          isCustom
          className={`card-inner mt10 d-flex cursor-pointer w100p ${isSelected('one-off') ? 'chosen-goal' : ''}`}
          onClick={() => {
            setPaymentPlan('one-off');
          }}
        >
          <div className="text-left">
            <h4>{t('SUBSCRIPTION.ONE-OFF-PURCHASE')}</h4>
            <h3>£{numberFormatter(amount, 2)}</h3>
            <p className={`small ${isSelected('one-off') ? 'text-white' : 'text-faded'} pt0`}>
              un:hurd music service fee
            </p>
          </div>
          <div className="mt-auto mb-auto ml-auto">
            {!isSelected('one-off') ? (
              <Icon className="material-symbols-outlined text-faded">circle</Icon>
            ) : (
              <Icon className="text-white">check_circle</Icon>
            )}
          </div>
        </ButtonComponent>
      )}
    </>
  );
};

export default PaymentOptions;
