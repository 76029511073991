import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const Summary = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('PLAYLISTING.SUMMARY')}</h3>
      <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.SUMMARY-DESCRIPTION')}</p>
      <div className="card d-flex gap20 flex-wrap">{children}</div>
    </>
  );
};

export default Summary;
